<template>
  <el-container>
    <el-main>
      <div class="blodTitle">微信小程序</div>
      <img :style="{ marginBottom: isShouQuan ? '' : '20px' }" src="../../assets/image/wxSetHeader.png" alt="" />
      <!-- 判断用户是否授权 v-if="!dataInfo.appstatus" true->未授权 -->
      <div class="isAuthorization" v-if="!dataInfo.appstatus">
        <div class="Authorizations" v-for="(item, index) in 2" :key="index">
          <div class="top">{{ index == 0 ? '未有微信小程序' : '已有微信小程序' }}</div>
          <div class="mid">
            {{ index == 0 ? '在微信公众平台注册小程序，完成注册后可以同步进行 信息完善和开发。' : '授权已有的微信小程序，完成支付配置设置，发布快速获得小程序' }}
          </div>
          <div class="bot" @click="regiOrGrant(index)">{{ index == 0 ? '快速注册' : '授权微信小程序' }}</div>
        </div>
      </div>
      <div v-else>
        <div class="blodTitle">授权及发布小程序</div>
        <Empower v-if="dataInfo" style="height: 600px" @getInfo="getInfo" :dataInfo="dataInfo" ref="Empower"></Empower>
      </div>
      <div class="blodTitle" style="margin-top: 20px" v-if="dataInfo && dataInfo.appstatus">
        配置业务域名
        <div class="setWebView" @click="setWebView">确认配置</div>
      </div>
      <div class="blodTitle">其他配置</div>
      <div class="otherConfig">
        <div class="configItem" v-for="(item, index) in otherConifg" :key="index">
          <img :src="item.img" alt="" />
          <div class="conTxt">
            <div class="top">{{ item.title }}</div>
            <div class="mid">{{ item.content }}</div>
            <div class="bot">
              <span @click="Jump(index)">{{ index == 0 ? '前往配置' : '前往体验' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="blodTitle">特色功能</div>
      <div class="functions">
        <div v-for="(item, index) in Characteristicfunction" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
      <div class="blodTitle">
        优秀案例
        <span class="subTitle">更多案例</span>
      </div>
      <div class="cases">
        <div v-for="(item, index) in moreCase" :key="index">
          <img :src="imgUrlHead + 'sharecode/' + item.shareCode" alt="" />
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
      <div class="blodTitle">接入流程</div>
      <div class="process">
        <div class="processItem" v-for="(item, index) in process" :key="index">
          <div class="left">{{ index + 1 }}</div>
          <div class="right">
            <div class="rTop">{{ item.title }}</div>
            <div class="rBot">{{ item.desc }}</div>
          </div>
          <div class="yuanBox" v-if="index <= process.length - 2">
            <div class="yuan" v-for="(item1, index1) in 5" :key="index1"></div>
          </div>
        </div>
      </div>
      <el-dialog title="体验小程序" :visible.sync="experienceFlag" width="52%">
        <Experience type="wx" :appstatus="dataInfo.appstatus" ref="Experience"></Experience>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import Empower from './sonPage/wxSon/empower';
import Experience from './sonPage/wxSon/experience';
import { imgUrlHead } from '../../util/config';
export default {
  components: {
    Empower,
    Experience,
  },
  data() {
    return {
      experienceFlag: false,
      imgUrlHead,
      dataInfo: {},
      appMsg: {},
      firstLoad: true,
      Characteristicfunction: [
        { title: '接入附近的小程序功能', content: '附近的小程序是线下商户在微信内重要的一个曝光渠道，通过附近的小程序，用户可以快速查看到门店信息以及享受到小程序服务' },
        { title: '小程序接入订阅消息', content: '消息推送，快捷发送商品，快捷提示。降低运营难度，确保消息送达率的同时，降低短信成本。随时对粉丝进行消息推送。增加粉丝黏性' },
        { title: '微信搜索功能', content: '开通微信搜索后，商家将获得丰富的展示能力。用户可以通过搜索品牌名称直达商家，同时，商家的服务和物品也有机会出现在相关搜索结果中。' },
        { title: '自定义装修小程序', content: '无需代码，快速制作，丰富的行业模板，任意更换，一键生成微信小程序。也可根据自己的风格装修专属自己的小程序，多种组件轻松上手。' },
      ],
      moreCase: [
        { name: '日易惠百货用品', shareCode: '01.jpg' },
        { name: '天宜世家家居', shareCode: '02.jpg' },
        { name: '新稻香农产品', shareCode: '03.jpg' },
        { name: '雅兰天丽美妆', shareCode: '04.jpg' },
        { name: '伊乐贝斯女装', shareCode: '05.jpg' },
      ],
      process: [
        { title: '在微信公众平台注册小程序账号', desc: '在微信公众平台注册小程序，完成注册后可以同步进行信息完善和开发。' },
        { title: '在彩蛋后台一键授权微信小程序', desc: '完成小程序开发者绑定、开发信息配置后，开发者可下载开发者工具、参考开发文档进行小程序的开发和调试。' },
        { title: '提交审核和发布', desc: '完成小程序开发后，提交代码至微信团队审核，审核通过后即可发布。' },
      ],
      otherConifg: [
        {
          img: require('@/assets/image/dingyue.png'),
          title: '订阅消息',
          content: '登录微信公众号平台，增加小程序类目，为：商家自营 > 服装/鞋/箱包；符合第三方一键配置，可以一键配置，完成所有订阅消息配置。',
        },
        {
          img: require('@/assets/image/tiyan.png'),
          title: '体验小程序',
          content: '上传小程序后可生成体验码，可在手机上预览小程序。小程序未上线前，可以添加体验者微信号，即可手机浏览小程序。',
        },
      ],
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    Jump(index) {
      if (index == 0) {
        this.$router.push('/shopManagement/wxsubscribe');
      } else if (index == 1) {
        if (this.dataInfo.appstatus >= 2) {
          this.experienceFlag = true;
          this.$nextTick(() => {
            this.$refs.Experience.getQRcode();
          });
        } else {
          this.$message.warning('请先上传小程序');
        }
      }
    },
    // 注册微信小程序或者授权平台
    regiOrGrant(index) {
      if (index === 0) {
        window.open('https://mp.weixin.qq.com/');
      } else if (index === 1) {
        if (this.dataInfo && this.dataInfo.appstatus >= 1) {
          this.$message.warning('您已上传过小程序，请联系客服进行更改');
          return;
        }
        let that = this;
        this.$axios
          .post(this.$api.set.wxCode, {
            from: 0,
          })
          .then(res => {
            if (res.code == 0) {
              that
                .$confirm('是否完成授权', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then(() => {
                  this.getInfo(1);
                })
                .catch(() => {});
              window.open(res.result, '_blank');
            }
          });
      }
    },
    // 获取初始状态值
    getInfo(status) {
      let that = this;
      this.$axios.post(this.$api.set.wxApp, {}).then(res => {
        if (res.code == 0) {
          that.dataInfo = res.result;
          if (!that.dataInfo.version_new) that.dataInfo.version_new = {};
          if (this.$route.query.active) {
            if (this.firstLoad) {
              this.activeName = this.$route.query.active;
              this.firstLoad = false;
            }
            if (this.dataInfo.appstatus >= 2) {
              this.$refs.Experience.getQRcode();
              this.$refs.Experience.getTryList();
            }
          }
          if (status == 1 && res.result.appstatus == 1) {
            that.$message.success('授权成功');
          } else if (status == 1 && res.result.appstatus < 1) {
            that.$message.error('授权失败');
          }
        }
      });
    },
    setWebView() {
      this.$axios.post(this.$api.user.wxwork.setWebView).then(res => {
        if (res.code == 0) {
          this.$message.success('配置成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  width: 100%;
  background: #fff;
}
.el-main {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 20px;
  .blodTitle {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin: 40px 0 20px 0;
    .subTitle {
      color: #5654f3;
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      margin-left: 23px;
    }
    .setWebView {
      display: inline-block;
      width: 96px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      border-radius: 2px;
      cursor: pointer;
      background-color: #06ba56;
      margin-left: 20px;
      color: #fff;
    }
  }
  & > img {
    width: 100%;
  }
  .isAuthorization {
    width: 100%;
    display: flex;
    & > div {
      width: 380px;
      padding: 20px;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #f7f8fa;
      margin-right: 20px;
    }
    .top {
      font-size: 18px;
      color: #333;
      text-align: center;
      font-weight: bold;
    }
    .mid {
      width: 100%;
      font-size: 14px;
    }
    .bot {
      width: 228px;
      height: 32px;
      background: #00ca5a;
      border-radius: 2px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      align-self: center;
    }
  }
  .functions {
    display: flex;
    & > div {
      flex: 1;
      padding: 16px;
      background: #ffffff;
      border: 1px solid #ededed;
      border-radius: 2px;
      .title {
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-bottom: 16px;
      }
      .content {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }
    & > div:not(:first-child) {
      margin-left: 20px;
    }
  }
  .cases {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    & > div {
      margin-right: 90px;
      flex-shrink: 0;
      img {
        width: 143px;
        height: 143px;
      }
      div {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #333;
        margin-top: 10px;
      }
    }
  }
  .process {
    display: flex;
    height: 60px;
    margin-bottom: 70px;
    justify-content: space-between;
    .processItem {
      flex: 1;
      display: flex;
      align-items: center;
      .left {
        width: 46px;
        height: 46px;
        flex-shrink: 0;
        text-align: center;
        line-height: 46px;
        border: 2px solid #d8d8d8;
        border-radius: 50%;
        margin-right: 16px;
      }
      .right {
        // width: 350px;
        .rTop {
          font-size: 16px;
          font-weight: 500;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .rBot {
          font-size: 14px;
          font-weight: 500;
          color: #999999;
          line-height: 18px;
          margin-top: 10px;
        }
      }
      .yuanBox {
        display: flex;
        align-items: center;
        margin: 0 37px;
        .yuan {
          width: 8px;
          height: 8px;
          background: #d9d9d9;
          border-radius: 50%;
          &:not(:first-child) {
            margin-left: 12px;
          }
        }
      }
    }
  }
}
.otherConfig {
  width: 100%;
  display: flex;
  .configItem {
    flex: 1;
    display: flex;
    align-items: center;
    border: 1px solid #ededed;
    border-radius: 2px;
    padding: 17px 20px;
    img {
      width: 50px;
      height: 50px;
    }
    .conTxt {
      margin-left: 20px;
      width: calc(100% - 65px);
      .top {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 4px;
      }
      .mid {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
      .bot {
        margin-top: 4px;
        span {
          color: #00b852;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
  }
  .configItem:last-child {
    margin-left: 15px;
  }
}
</style>
